/* eslint-disable-next-line */
//@ts-ignore
import { Row, Spinner, DropdownToggle, DropdownMenu } from 'reactstrap'
import styled, { css } from 'styled-components'
import { X } from 'react-feather'
//@ts-ignore
import { Avatar } from 'dsm-react-lib'

export type AlertStyleType = {
  [key: string]: {
    color: string
    backgroundColor: string
    hoverBackgroundColor: string
  }
}

export const SpinnerWrapper = styled(Row)`
  display: flex;
  justify-content: center;
  min-height: ${(props) => props.height || '500px'};
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  align-content: center;
`

export const ButtonSpinner = styled(Spinner)`
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
`

export const alertStyles: AlertStyleType = {
  critical: {
    color: '#eb5c37',
    backgroundColor: '#fbded7',
    hoverBackgroundColor: '#F7BDAF'
  },
  success: {
    color: '#7ebd5f',
    backgroundColor: '#E5F1DF',
    hoverBackgroundColor: '#CBE4BF'
  },
  warning: {
    color: '#ffb81c',
    backgroundColor: '#FFF0D1',
    hoverBackgroundColor: '#FFE2A4'
  },
  info: {
    color: '#65b4e5',
    backgroundColor: '#E0F0F9',
    hoverBackgroundColor: '#C1E1F4'
  }
}

export const NewDot = styled.span`
  border-radius: 50%;
  background-color: #7ebd5f;
  min-width: 1rem;
  height: 1rem;
  margin: 0.3rem 1rem 0 0;
  display: block;
  box-shadow: 0px 0px 0px 5px rgba(126, 189, 95, 0.1);
`

export const CloseBtn = styled(X)`
  cursor: pointer;
  color: ${(props) => props.color || 'white'};
  transition: transform 0.2s ease;
  &:hover {
    transform: scale(1.2);
  }
`

export const MiscWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;

  .inner {
    position: relative;
    max-width: 750px;
    margin-top: 4rem;
    padding: 2rem;
  }
`
export const BrandLogo = styled.a`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 2rem;
  left: 2rem;
  margin: 0;

  .brand-text {
    font-weight: 600;
  }
`

export const StyledDropdownToggle = styled(DropdownToggle)`
  padding: 0.5rem;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  color: #055a60;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      &:hover {
        border-radius: 50%;
        background-color: rgba(5, 90, 96, 0.12);
      }
    `}
`

export const DropdownMenuContainer = styled(DropdownMenu)`
  button {
    &:active {
      color: #fff !important;
      a {
        color: #fff !important;
      }
    }
  }
`

export const UserAvatar = styled(Avatar)`
  cursor: default !important;
`
