// ** React Imports
import { Suspense, lazy } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
// import { reactPlugin } from './services/appInsights'

// ** Redux Imports
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './_redux/store'

import { ThemeContext } from './utility/context/ThemeColors'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** Ripple Button
import './@core/components/ripple-button'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import 'dsm-react-lib/dist/index.css'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import { reactPlugin } from './_services/AppInsights'
import { ThemeProvider } from 'styled-components'
import { mgInvestments } from './assets/styles/mgInvestments'
import { ErrorBoundary } from './router/ErrorBoundary'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig, loginRequest } from './authConfig'
import { MsalProvider } from '@azure/msal-react'
import { SplashScreen } from './views/landing-partials/SplashScreen'

//**React Drag and drop
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

const msalInstance = await PublicClientApplication.createPublicClientApplication(msalConfig)

const root = createRoot(document.getElementById('root'))

const handleResponse = (response) => {
  let accountId = ''
  if (response !== null) {
    accountId = response.account.homeAccountId
  } else {
    const currentAccounts = msalInstance.getAllAccounts()
    if (currentAccounts.length === 0) {
      msalInstance.loginRedirect(loginRequest)
    } else if (currentAccounts.length === 1) {
      accountId = currentAccounts[0].homeAccountId
    }
  }
}

msalInstance.handleRedirectPromise().then(handleResponse)

root.render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={'Persist gate loading....'} persistor={persistor}>
          <Suspense fallback={<></>}>
            <ThemeContext>
              <ThemeProvider theme={mgInvestments}>
                <SplashScreen />
                <MsalProvider instance={msalInstance}>
                  <Router>
                    <DndProvider backend={HTML5Backend}>
                      <LazyApp />
                    </DndProvider>
                  </Router>
                </MsalProvider>
              </ThemeProvider>
            </ThemeContext>
          </Suspense>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </AppInsightsContext.Provider>
)

serviceWorker.register()
