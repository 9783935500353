import { getConfig } from './configs/config'

const config = getConfig()

export const msalConfig = {
  auth: {
    clientId: config.REACT_APP_AZURE_CLIENT_ID,
    authority: config.REACT_APP_AZURE_AUTHORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: config.REACT_APP_AZURE_REDIRECT_URL
  },
  system: {
    allowRedirectInIframe: true
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    claimsBasedCachingEnabled: true
  }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [config.REACT_APP_AZURE_SCOPE]
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}
